<template>
  <div>
    <div class="center">
      <div v-if="error" class="alert alert-danger" role="alert">{{ error }}!</div>
    </div>
    <div v-if="device" class="container">
      <div class="">
        <h1 class="text-center">{{ "معلومات المعدة" | translate }}</h1>
        <hr />
        <div class="group" dir="rtl">
          <h3 class="heading">
            {{ device.category }}
          </h3>
          <div class="row justify-content-center">
            <div class="col-md-6 p-0">
              <div class="details p-md-4">
                <div class="item">
                  <div class="prop">{{ "الموقع" | translate }}:</div>
                  <p>{{ device.location }}</p>
                </div>
                <div class="item">
                  <div class="prop">{{ "كود المعدة" | translate }}:</div>
                  <p>{{ device.code }}</p>
                </div>
                <hr />
              </div>
            </div>
            <div class="col-md-6 center">
              <qr-code
                :id="id"
                :name="`${id}-${device.category}-${device.location}-${device.code}.png`"
              ></qr-code>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!---------------------------------------------------------------------->
      <!---------------------------------------------------------------------->
      <!---------------------------------------------------------------------->
      <!---------------------------------------------------------------------->
      <div v-if="auth.role == 0 || auth.role == 2" class="followup">
        <h2 class="text-center">{{ "تقرير المتابعة" | translate }}</h2>
        <div class="row g-3 justify-conent-cent">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ "الحالة" | translate }}</label>
              <select class="form-control" v-model="state">
                <option v-for="state in states" :key="state.code" :value="state.code">
                  {{ state.desc }} ({{ state.code }})
                </option>
              </select>
            </div>
          </div>
          <!--------------------------->
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ "الأجراء" | translate }}</label>
              <select class="form-control" v-model="procedure">
                <option
                  v-for="procedure in procedures"
                  :key="procedure.code"
                  :value="procedure.code"
                >
                  {{ procedure.desc }} ({{ procedure.code }})
                </option>
              </select>
            </div>
          </div>
          <!-------------------->
          <div class="col-md-6">
            <div class="form-group">
              <label>{{ "التاريخ" | translate }}</label>
              <input type="date" class="form-control" v-model="date" />
            </div>
          </div>
          <div v-if="catElement != 0" class="col-md-6">
            <div class="form-group">
              <label>{{ catElement }}</label>
              <input type="number" min="0" class="form-control" v-model="element" />
            </div>
          </div>
        </div>
        <!------------------->
        <div v-if="loading" class="row justify-content-center">
          <div class="col-md-5">
            <loading />
          </div>
        </div>
        <!------------------->
        <hr />
        <div class="row g-2 justify-content-center">
          <div class="col-md-4 center">
            <button @click="addRecord" class="btn btn-success">
              {{ "رفع المتابعة" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4 center">
          <button @click="$router.push('/')" class="btn btn-danger">
            {{ "فحص الة جديدة" | translate }}
          </button>
        </div>
      </div>
      <hr />
      <br />
      <!---------------------------------------------------------------------->
      <!---------------------------------------------------------------------->
      <!---------------------------------------------------------------------->
      <!---------------------------------------------------------------------->
      <div class="records">
        <h4 class="text-center">{{ "سجل المتابعة للمعدة شهر" }} ({{ month }})</h4>
        <br />
        <app-table
          title=""
          :loading="recordsLoading"
          :items="records"
          :colsNames="['التاريخ', 'الحالة', 'الأجراء', 'الآفَة', 'الموظف', 'location', 'code', 'offline action']"
          :cols="['date', 'state', 'procedure', 'element', 'employeeName', 'location', 'code', 'action']"
          :btns="[
            {
              icon: 'pencil',
              method: (item) => $router.push(`/edit-record/${item._id}`),
            },
            {
              icon: 'trash',
              method: (item) => deleteRecord(item),
            },
          ]"
          color="#173129"
        />
      </div>
      <hr />

      <!---------->
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div
            class="plus-minus d-flex justify-content-between"
            style="direction: rtl !important"
          >
            <button
              @click="changeMonth('+')"
              class="btn btn-outline-secondary btn-rounded m-1"
            >
              <i class="fa fa-arrow-right"></i>
            </button>
            <button
              @click="changeMonth('-')"
              class="btn btn-outline-secondary btn-rounded m-1"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <!---------------------->
          <!-- <div
            v-if="!online"
            class="plus-minus d-flex justify-content-between"
            style="direction: rtl !important"
          >
            <button
              @click="month=+new Date().getMonth()+1"
              class="btn btn-outline-secondary btn-rounded m-1"
            >
              {{+new Date().getMonth() | month}}
            </button>
            <button
              v-if="+new Date().getMonth()>=0"
              @click="month=+new Date().getMonth()"
              class="btn btn-outline-secondary btn-rounded m-1"
            >
              {{+new Date().getMonth()-1 | month}}
            </button>
          </div> -->
        </div>
      </div>
      <!---------------------------------------------------------------------->
      <!---------------------------------------------------------------------->
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.id = this.$route.params.id;
    this.device = this.$store.getters.locationDevices.find(
      (device) => device._id == this.id
    );
    if (!this.device) {
      this.$store.dispatch("writeMessage", "there is no such a device in your locations");
      return this.$router.back();
    }
    this.states = this.getStates();
    this.procedures = this.getProcedures();
    this.month = new Date().getMonth() + 1;
    this.$store.dispatch("getMonthRecordsByLocation", null, this.device);
  },
  data() {
    return {
      id: null,
      device: null,
      state: null,
      procedure: null,
      element: 0,
      date: new Date().toISOString().substring(0, 10),
      loading: false,
      recordsLoading: false,
      error: null,
      ///////////////////
      /////////////////////
      states: [],
      procedures: [],
      month: null,
    };
  },
  ////////////////////////////////////////////////////////////////////////////////////////
  computed: {
    auth() {
      return this.$store.getters.auth;
    },

    online() {
      return this.$store.getters.online;
    },

    catElement() {
      const deviceCat = this.device.category;
      if (!deviceCat) return "أفة";
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      if (!category) return "أفة";
      return category.element;
    },

    records() {
      console.log(this.month);
      return this.$store.getters.locationRecords.filter(
        (rec) => {
            const month = new Date(rec.date).getMonth() + 1;
            return (
              rec.code == this.device.code && 
              rec.category == this.device.category && 
              rec.action != 'delete' && 
              rec.location == this.device.location &&
              month == this.month
            );
          }
      ).map((rec) => {
        return {
          ...rec,
          state: `${rec.stateName} (${rec.state})`,
          procedure: `${rec.procedureName} (${rec.procedure})`,
        };
      });
    }
  },

  watch: {
    online(val) {
      if(!val) {
        this.month = new Date().getMonth() + 1;
      }
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////////
  methods: {
    async addRecord() {
      if (!this.state || !this.procedure) return alert("اكمل البيانات المطلوبة");
      // const date = this.date;
      const record = {
        category: this.device.category,
        location: this.device.location,
        code: this.device.code,
        state: this.state,
        stateName: this.states.find((state) => state.code == this.state).desc,
        element: this.element,
        elementName: this.catElement,
        procedure: this.procedure,
        procedureName: this.procedures.find(
          (procedure) => procedure.code == this.procedure
        ).desc,
        employeeId: this.auth.userId,
        employeeName: this.auth.username,
        employeePhone: this.auth.phone,
        date: this.date,
      };
      this.$store.dispatch("addRecord", record);      
    },
    ///////////////////////////////////////////////////////
    changeMonth(opr) {
      // if (!this.online) return;
      if (opr == "+") {
        if (this.month < +new Date().getMonth() + 1) this.month++;
        else return;
      } else if (opr == "-") {
        if (this.month > 1) this.month--;
        else return;
      }
      this.$store.dispatch("getMonthRecordsByLocation", this.month, this.device);
    },
    ///////////////////////////////////////////////////////
    getStates() {
      const deviceCat = this.device.category;
      if (!deviceCat) return [];
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      return category.states;
    },

    getProcedures() {
      const deviceCat = this.device.category;
      if (!deviceCat) return [];
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      return category.procedures;
    },

    deleteRecord(item) {
      if (!confirm("are you sure ??")) return;
      if (item.employeeId != this.auth.userId) return alert("غير مسموح لك");
      this.$store.dispatch("deleteRecord", item);
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  color: #000000;
}
</style>